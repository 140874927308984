// .env constants
const {
    REACT_APP_ENV,
    REACT_APP_VERSION,

    REACT_APP_APP_NAME,
    REACT_APP_URL,
    REACT_APP_API_URL,

    REACT_APP_BUGSNAG_KEY,
    REACT_APP_GOOGLE_MAPS_KEY,
} = process.env;

// APP CONSTANTS
const app = {
    ENV: REACT_APP_ENV,
    VERSION: REACT_APP_VERSION || '0.0.0',
    DEBUG: REACT_APP_ENV === 'local',
    MOCK: false,
    BACKEND: 'yii', // or 'laravel'

    APP_NAME: REACT_APP_APP_NAME || 'Scorchsoft React Boilerplate',

    APP_URL: REACT_APP_URL,
    API_URL: REACT_APP_API_URL,

    BUGSNAG_KEY: REACT_APP_BUGSNAG_KEY,
    GOOGLE_MAPS_KEY: REACT_APP_GOOGLE_MAPS_KEY,

    AUTH_KEY: 'auth-key',
    LOCALSTORAGE_EXPIRES_KEY: 'session-token-expiry',
    LOCALSTORAGE_EXPIRES_TIME: '30', // minutes
};

export const REDUX_DATE_FORMAT = 'YYYY-MM-DD';
export const CALENDAR_WEEKS = 4;

export const LIMITED_AVAILABILITY_THRESHOLD = 12;
export const dateStyles = {
    available: {
        text: {
            color: 'blue',
        },
    },
    limited: {
        text: {
            color: 'darkgrey',
        },
    },
    past: {
        text: {
            color: 'grey',
        },
    },
    unavailable: {
        text: {
            color: 'grey',
            textDecorationLine: 'line-through',
        },
    },
    selected: {
        container: {
            backgroundColor: 'purple',
        },
        text: {
            color: 'white',
        },
    },
    today: {
        container: {
            backgroundColor: 'white',
            borderColor: 'purple',
            borderWidth: 1,
        },
        text: {
            color: 'purple',
        },
    },
};

export default app;
