import React from 'react';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import './beginBooking.scss';

const BeginBooking = () => {
    const navigate = useNavigate();

    const navigateToSecurity = () => {
        navigate('/security');
    };

    return (
        <div className="booking-page" title="Book your appointment">
            <h1 className="booking-title">Book your appointment</h1>
            <p className="booking-description">Your Welbeck clinician or GP has begun creating an appointment for you. Please fill out this form to complete your booking.</p>
            <Button type="primary" className="begin-button" onClick={navigateToSecurity}>Begin</Button>
        </div>
    );
};

export default BeginBooking;
