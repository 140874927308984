/* eslint-disable react/no-array-index-key */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import SlotDisplay from '../SlotDisplay';
import CustomRadio from '../CustomRadio';
import RequestModal from '../RequestModal';
import SuccessModal from '../SuccessModal';
import FailureModal from '../FailureModal';
import './availabilityCalendar.scss';

moment.updateLocale('en', {
    week: {
        dow: 1,
    },
});

const REDUX_DATE_FORMAT = 'YYYY-MM-DD';

const availabilityOptions = [
    { value: 'available', label: 'Available', colorClass: 'available' },
    { value: 'limited', label: 'Limited Availability', colorClass: 'limited' },
];

const AvailabilityCalendar = ({ availabilityData, firstAvailableSlot, preSelectedSlot, onSelectSlot, gpUser }) => {
    const [selectedDate, setSelectedDate] = useState(preSelectedSlot?.date || firstAvailableSlot?.date);
    const [markedDates, setMarkedDates] = useState({});
    const [selectedAvailability, setSelectedAvailability] = useState('available');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isSuccessModalVisible, setIsSuccessModalVisible] = useState(false);
    const [isFailureModalVisible, setIsFailureModalVisible] = useState(false);

    const handleDateSelection = (date) => {
        const formattedDate = moment(date).format(REDUX_DATE_FORMAT);
        if (formattedDate === selectedDate || !availabilityData[formattedDate]) {
            return;
        }
        setSelectedDate(formattedDate);
    };

    const baseDatesMemo = useMemo(() => {
        const today = moment();
        const endDate = today.clone().add(4, 'weeks').endOf('isoWeek');

        const newMarkedDates = {};
        let currentDate = today;

        while (currentDate.isBefore(endDate, 'day') || currentDate.isSame(endDate, 'day')) {
            const dateKey = currentDate.format(REDUX_DATE_FORMAT);
            const dateAvailability = availabilityData ? availabilityData[dateKey] : null;

            if (dateAvailability && dateAvailability.availability !== 'unavailable') {
                newMarkedDates[dateKey] = {
                    disabled: false,
                    customStyles: 'available',
                };
            } else {
                newMarkedDates[dateKey] = {
                    disabled: true,
                    customStyles: 'unavailable',
                };
            }

            currentDate = currentDate.clone().add(1, 'day');
        }

        return newMarkedDates;
    }, [availabilityData]);

    useEffect(() => {
        const setSelectedMarkedDate = (baseDates, date) => {
            const newMarkedDates = { ...baseDates };
            if (date) {
                newMarkedDates[date] = {
                    ...newMarkedDates[date],
                    customStyles: 'selected',
                };
            }
            return newMarkedDates;
        };

        const newMarkedDates = setSelectedMarkedDate(baseDatesMemo, selectedDate);
        setMarkedDates(newMarkedDates);
    }, [baseDatesMemo, selectedDate]);

    const slots = useMemo(() => {
        const groupedSlots = { morning: [], afternoon: [], evening: [] };
        const slotData = availabilityData[selectedDate]?.slots || [];
        slotData.forEach((slot) => {
            const hour = moment(slot.startTime, 'HH:mm').hour();
            if (hour < 12) groupedSlots.morning.push(slot);
            else if (hour < 17) groupedSlots.afternoon.push(slot);
            else groupedSlots.evening.push(slot);
        });
        return {
            slotGroups: groupedSlots,
            totalSlots: slotData.length,
            preSelectedSlot: preSelectedSlot?.date === selectedDate ? preSelectedSlot : null,
        };
    }, [availabilityData, selectedDate, preSelectedSlot]);

    const generateCalendarRows = () => {
        const weeks = [];
        let week = [];
        const today = moment();
        const startDate = today.clone().startOf('isoWeek');
        const endDate = today.clone().add(4, 'weeks').endOf('isoWeek');
        let currentDate = startDate;

        while (currentDate.isBefore(endDate, 'day') || currentDate.isSame(endDate, 'day')) {
            if (week.length === 0) {
                for (let i = 0; i < currentDate.isoWeekday() - 1; i++) {
                    week.push('');
                }
            }

            if (currentDate.date() === 1 && currentDate.month() !== startDate.month() && week.length > 0) {
                while (week.length < 7) {
                    week.push('');
                }
                weeks.push(week);
                week = [];
                for (let i = 0; i < currentDate.isoWeekday() - 1; i++) {
                    week.push('');
                }
            }

            week.push(currentDate.clone());
            currentDate = currentDate.clone().add(1, 'day');

            if (week.length === 7) {
                weeks.push(week);
                week = [];
            }
        }

        while (week.length < 7) {
            week.push('');
        }
        weeks.push(week);

        return weeks;
    };

    const calendarRows = generateCalendarRows();
    const weekdays = moment.weekdaysShort(true);
    const monthHeaders = [];

    const handleSubmit = (isSuccess) => {
        setIsModalVisible(false);
        if (isSuccess) {
            setIsSuccessModalVisible(true);
        } else {
            setIsFailureModalVisible(true);
        }
    };

    return (
        <>
            <div className="availability-calendar">
                <div className="normal-header">
                    <p className="duration-text">Expected Duration: 45 Minutes</p>
                </div>
                <div className="availability-options">
                    <CustomRadio
                        options={availabilityOptions}
                        name="availability"
                        selectedOption={selectedAvailability}
                        disabled
                        onChange={setSelectedAvailability} />
                </div>
                <div className="request-container">
                    {!gpUser && (
                        <button type="button" className="request-further-text" onClick={() => setIsModalVisible(true)}>
                            Request Further Availability
                        </button>
                    )}
                </div>
                <div className="calendar-container custom-calendar">
                    <div className="week-row weekdays">
                        {weekdays.map(day => (
                            <div key={day} className="day-cell weekday">{day}</div>
                        ))}
                    </div>
                    {calendarRows.map((week, weekIndex) => {
                        const monthName = week.find(date => date && date !== '' && moment.isMoment(date))?.format('MMMM');
                        if (monthName && !monthHeaders.includes(monthName)) {
                            monthHeaders.push(monthName);
                            return (
                                <React.Fragment key={`month-${weekIndex}`}>
                                    <div className="month-header">{monthName}</div>
                                    <div className="week-row">
                                        {week.map((date, dayIndex) => {
                                            if (date === '') return <div key={`empty-${weekIndex}-${dayIndex}`} className="day-cell empty" />;

                                            const dateKey = date.format(REDUX_DATE_FORMAT);
                                            const isSelected = dateKey === selectedDate;
                                            const isToday = date.isSame(moment(), 'day');
                                            const isAvailable = markedDates[dateKey]?.customStyles === 'available';
                                            const isUnavailable = markedDates[dateKey]?.customStyles === 'unavailable';
                                            const isBeforeToday = date.isBefore(moment(), 'day');

                                            let dayClass = '';
                                            if (isSelected) {
                                                dayClass = 'selected';
                                            } else if (isToday) {
                                                dayClass = 'today';
                                            } else if (isAvailable) {
                                                dayClass = 'available';
                                            } else if (isUnavailable) {
                                                dayClass = 'unavailable';
                                            }

                                            return (
                                                <div
                                                    key={dateKey}
                                                    className={`day-cell ${dayClass}`}
                                                    onClick={() => !isBeforeToday && handleDateSelection(date.toDate())}>
                                                    <span style={{ visibility: isBeforeToday ? 'hidden' : 'visible' }}>
                                                        {date.date()}
                                                    </span>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </React.Fragment>
                            );
                        }
                        return (
                            <div key={`week-${weekIndex}`} className="week-row">
                                {week.map((date, dayIndex) => {
                                    if (date === '') return <div key={`empty-${weekIndex}-${dayIndex}`} className="day-cell empty" />;

                                    const dateKey = date.format(REDUX_DATE_FORMAT);
                                    const isSelected = dateKey === selectedDate;
                                    const isToday = date.isSame(moment(), 'day');
                                    const isAvailable = markedDates[dateKey]?.customStyles === 'available';
                                    const isUnavailable = markedDates[dateKey]?.customStyles === 'unavailable';
                                    const isBeforeToday = date.isBefore(moment(), 'day');

                                    let dayClass = '';
                                    if (isSelected) {
                                        dayClass = 'selected';
                                    } else if (isToday) {
                                        dayClass = 'today';
                                    } else if (isAvailable) {
                                        dayClass = 'available';
                                    } else if (isUnavailable) {
                                        dayClass = 'unavailable';
                                    }

                                    return (
                                        <div
                                            key={dateKey}
                                            className={`day-cell ${dayClass}`}
                                            onClick={() => !isBeforeToday && handleDateSelection(date.toDate())}>
                                            <span style={{ visibility: isBeforeToday ? 'hidden' : 'visible' }}>
                                                {date.date()}
                                            </span>
                                        </div>
                                    );
                                })}
                            </div>
                        );
                    })}
                </div>
            </div>
            <SlotDisplay
                key={`slot-display-${selectedDate}`}
                slots={slots}
                onSelectSlot={(slot) => onSelectSlot({ ...slot, date: selectedDate })} />
            <RequestModal visible={isModalVisible} onClose={() => setIsModalVisible(false)} onSubmit={handleSubmit} />
            <SuccessModal visible={isSuccessModalVisible} onClose={() => setIsSuccessModalVisible(false)} />
            <FailureModal visible={isFailureModalVisible} onClose={() => setIsFailureModalVisible(false)} />
        </>
    );
};

export default AvailabilityCalendar;

AvailabilityCalendar.propTypes = {
    availabilityData: PropTypes.object.isRequired,
    firstAvailableSlot: PropTypes.shape({
        date: PropTypes.string,
        slot: PropTypes.string,
    }).isRequired,
    preSelectedSlot: PropTypes.shape({
        date: PropTypes.string,
        slot: PropTypes.string,
    }),
    onSelectSlot: PropTypes.func.isRequired,
    gpUser: PropTypes.bool,
};

AvailabilityCalendar.defaultProps = {
    preSelectedSlot: null,
    gpUser: false,
};
