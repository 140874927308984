import { all } from 'redux-saga/effects';
import userSaga from './user/userSaga';
import regionSaga from './region/regionSagas';

// single entry point to start all Sagas at once
export default function* rootSaga() {
    yield all([
        userSaga(),
        regionSaga(),
    ]);
}
