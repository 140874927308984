export function trimString(string) {
    if (!string) {
        return '';
    }

    return string.trim().replace(/[\n\r]/g, '');
}

export const test = () => {
    console.log('test');
};
