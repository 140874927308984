import React from 'react';
import { Button, Input, Upload } from 'antd';
import { useNavigate } from 'react-router-dom';
import { UploadIcon } from '../../components/Elements/CustomSVGIcon';
import ProgressBar from '../../components/ProgressBar';
import logoIcon from '../../assets/images/welbeck-logo.png';
import './attachDocument.scss';

const { TextArea } = Input;

const AttachDocument = () => {
    const navigate = useNavigate();

    const handleNextClick = () => {
        navigate('/complete-booking');
    };

    return (
        <div className="attach-page">
            <div className="header">
                <img src={logoIcon} alt="Logo" className="logo" />
            </div>
            <div className="progress-indicator">
                <ProgressBar progress={1} />
            </div>
            <p className="booking-info">
                If there are any documents you need to provide for this appointment - e.g. referral letter - or have any notes to mention, please do so here.
            </p>
            <div className="document-upload-container">
                <Upload accept=".png,.pdf" multiple={false} className="document-upload">
                    <div className="upload-area">
                        <Button icon={<UploadIcon />} type="link" className="form-upload">Attach Form</Button>
                        <p className="upload-description">You can attach a PNG or PDF of your referral letter.</p>
                    </div>
                </Upload>
            </div>
            <div className="notes">
                <p className="notes-title">Notes (Optional)</p>
                <TextArea placeholder="e.g. name of your preferred referral doctor" rows={4} />
            </div>
            <Button type="primary" className="next-button" onClick={handleNextClick}>Next</Button>
        </div>
    );
};

export default AttachDocument;
