import React, { useState } from 'react';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import Accordion from '../../components/Accordion';
import ProgressBar from '../../components/ProgressBar';
import logoIcon from '../../assets/images/welbeck-logo.png';
import './appointment.scss';
import { CalendarIcon, ClockIcon, IconTickFilled, FileOutlined } from '../../components/Elements/CustomSVGIcon';

const initialData = [
    {
        id: 1,
        title: 'MRI Brain, Contrast, Bilateral',
        content: {
            date: 'Thursday 21st March',
            time: '10:00',
            documents: [
                { id: 1, name: 'Referral Letter.pdf' },
                { id: 2, name: 'Previous MRI Scan.png' },
            ],
        },
        hasCompleted: true,
    },
    {
        id: 2,
        title: 'MRI Elbow, Contrast, Right',
        content: {
            date: 'Friday 22nd March',
            time: '11:00',
            documents: [
                { id: 3, name: 'Referral Letter.pdf' },
                { id: 4, name: 'X-ray Image.png' },
            ],
        },
        hasCompleted: false,
    },
];

const Appointment = () => {
    const [data, setData] = useState(initialData);
    const navigate = useNavigate();

    const handleBookAppointment = (id) => {
        setData((prevData) => prevData.map((item) => (item.id === id ? { ...item, hasCompleted: true } : item)));
    };

    const handleNavigateResult = () => {
        navigate('/booking-result');
    };

    const areAllCompleted = data.every((item) => item.hasCompleted);
    console.log('areAllCompleted', areAllCompleted);

    const renderItem = ({ item }) => (
        <div className="accordion-item">
            <div className="accordion-title">
                <h3>{item.title}</h3>
                {item.hasCompleted && (
                    <div className="completed-appointment">
                        <span className="completed-appointment-date">
                            <IconTickFilled className="icon" />
                            {item.content.time} on {item.content.date}
                        </span>
                    </div>
                )}
            </div>
            {item.hasCompleted && (
                <div className="submitted-documents">
                    {item.content.documents.map((doc) => (
                        <div key={doc.id} className="document-item">
                            <FileOutlined className="document-icon" />
                            {doc.name}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );

    const renderContent = ({ item }) => (
        <div className="accordion-content">
            <p className="next-appointment">Next Available Appointment</p>
            <div className="appointment-info">
                <span className="appointment-date">
                    <CalendarIcon className="icon" />
                    {item.content.date}
                </span>
                <span className="appointment-time">
                    <ClockIcon className="icon" />
                    {item.content.time}
                </span>
            </div>
            <div className="button-container">
                <Button type="default" className="book-appointment-button" onClick={() => handleBookAppointment(item.id)}>
                    Book Appointment
                </Button>
                <Button type="default" className="see-availability-button" onClick={() => navigate('/availability')}>
                    See Other Availability
                </Button>
            </div>
        </div>
    );

    return (
        <div className="appointment-page">
            <div className="header">
                <img src={logoIcon} alt="Logo" className="logo" />
            </div>
            <div className="progress-indicator">
                <ProgressBar progress={1} />
            </div>
            <div className="appointments">
                <h1>Appointments</h1>
                <p>Here are the appointments that have been referred to you. Please complete the booking for each appointment.</p>
                <Accordion
                    data={data}
                    renderItem={renderItem}
                    renderContent={renderContent}
                    multiOpen />
            </div>
            <Button
                type="primary"
                className="complete-booking-button"
                disabled={!areAllCompleted}
                onClick={handleNavigateResult}>
                Complete Booking
            </Button>
        </div>
    );
};

export default Appointment;
