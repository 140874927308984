import React from 'react';
import { Button } from 'antd';
import PropTypes from 'prop-types';
import { CheckCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import logoIcon from '../../assets/images/welbeck-logo.png';
import './bookingResult.scss';

const BookingResult = ({ success }) => (
    <div className="booking-result-page">
        <div className="header">
            <img src={logoIcon} alt="Logo" className="logo" />
        </div>
        <div className="result-container">
            {success ? (
                <>
                    <CheckCircleOutlined className="result-icon success" />
                    <h1 className="result-title">Booking Successful</h1>
                    <p className="result-message">
                        All appointments have successfully been booked at your requested times.
                    </p>
                </>
            ) : (
                <>
                    <ExclamationCircleOutlined className="result-icon error" />
                    <h1 className="result-title">Booking Unsuccessful</h1>
                    <p className="result-message">
                        We&apos;re sorry, there was an issue when completing one of the appointments in your booking.
                    </p>
                    <p className="appointment-details">
                        <strong>MRI Brain, Contrast, Bilateral</strong>
                    </p>
                    <p className="result-message">
                        OneWelbeck will be in touch to rearrange the appointment with you.
                    </p>
                    <Button type="primary" className="contact-button">
                        Continue With Booking
                    </Button>
                    <Button type="default" className="contact-button">
                        Contact OneWelbeck
                    </Button>
                </>
            )}
        </div>
    </div>
);

BookingResult.propTypes = {
    success: PropTypes.bool,
    appointmentDetails: PropTypes.shape({
        title: PropTypes.string,
    }),
};

BookingResult.defaultProps = {
    success: false,
    appointmentDetails: null,
};

export default BookingResult;
