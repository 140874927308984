import React from 'react';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import AvailabilityCalendar from '../../components/AvailabilityCalendar';
import logoIcon from '../../assets/images/welbeck-logo.png';
import './availability.scss';
import ProgressBar from '../../components/ProgressBar';

const fakeAvailabilityData = {
    '2024-07-11': { availability: 'available', slots: [{ startTime: '09:00', isAvailable: true }, { startTime: '10:00', isAvailable: false }] },
    '2024-07-12': { availability: 'limited', slots: [{ startTime: '11:00', isAvailable: true }, { startTime: '12:00', isAvailable: false }] },
    '2024-07-13': { availability: 'unavailable', slots: [] },
    '2024-08-04': { availability: 'available', slots: [{ startTime: '13:00', isAvailable: true }, { startTime: '14:00', isAvailable: true }] },
    '2024-08-05': { availability: 'available', slots: [{ startTime: '15:00', isAvailable: true }, { startTime: '16:00', isAvailable: true }] },
};

const firstAvailableSlot = { date: '2024-07-01', slot: '09:00' };

const Availability = () => {
    const navigate = useNavigate();

    const handleNextClick = () => {
        navigate('/attach-document');
    };

    return (
        <div className="availability-page">
            <div className="header">
                <img src={logoIcon} alt="Logo" className="logo" />
            </div>
            <div className="progress-indicator">
                <ProgressBar progress={1} />
            </div>
            <p className="booking-info">
                You are completing the booking for an MRI Brain, Contrast, Bilateral.
            </p>
            <div className="availability-content">
                <p className="instruction">Please select a date for the appointment:</p>
                <AvailabilityCalendar
                    availabilityData={fakeAvailabilityData}
                    firstAvailableSlot={firstAvailableSlot}
                    preSelectedSlot={null}
                    onSelectSlot={(slot) => console.log('Selected slot:', slot)}
                    gpUser={false} />
                <Button type="primary" className="next-button" onClick={handleNextClick}>
                    Next
                </Button>
            </div>
        </div>
    );
};

export default Availability;
